import { useCallback, useEffect, useState } from 'react';
import 'firebase/auth';
import 'firebase/firestore';
import * as Sentry from '@sentry/node';
import { useRouter } from 'next/router';

export type Error = {
  errors: FrontendError[];
  clearError: (id: string) => void;
  addError: (error: string) => void;
};

export type FrontendError = {
  id: string;
  error: string;
};
export default function useError(): Error {
  const { query, replace, pathname } = useRouter();
  const [errors, setErrors] = useState<FrontendError[]>([]);

  const addError = useCallback(
    (error: string) => {
      const errorId = (Math.random() * 1000000).toString();
      Sentry.captureException(error);
      setErrors([...errors, { id: errorId, error }]);
    },
    [errors]
  );

  useEffect(() => {
    if (query.error) {
      const { error, ...queryWithoutError } = query;
      addError(error as string);
      replace({ pathname, query: queryWithoutError }, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, pathname]);

  return {
    errors,
    clearError: (errorId: string) => {
      setErrors(errors.filter(({ id }) => errorId !== id));
    },
    addError,
  };
}
