import { init } from '../config/sentry';
import '../config/firebase/initialize';
import { useRouter } from 'next/router';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import * as locales from '../config/locales/compiled';
import { Locale } from 'antd/lib/locale-provider';
import React from 'react';
import '../assets/antd-custom.less';
import '../styles/vars.css';
// behind vars.css to have access to root css variables
import '../config/prettyPageLoader';
import '../config/prettyPageLoader/index.css';
import '../config/cookies/index.css';
import { UserAuthenticationProvider } from 'components/context/UserAuthenticationProvider';
import { GlobalStateProvider } from 'components/context/GlobalStateProvider';
import { ErrorProvider } from 'components/context/ErrorProvider';
import { CookieConsentProvider } from 'components/context/CookieConsentProvider';
import { FirebaseTrackingProvider } from 'components/context/FirebaseTrackingProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import Head from 'next/head';
import { ProfileProvider } from 'components/context/ProfileProvider';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

init();

type AppProps = {
  Component: ({ err }: { err: Error }) => JSX.Element;
  pageProps: Record<string, unknown>;
  // Workaround for https://github.com/vercel/next.js/issues/8592
  err: Error;
};

export default function MyApp({ Component, pageProps, err }: AppProps): JSX.Element {
  const { locale, defaultLocale } = useRouter();

  const localesMap = locales as unknown as Record<string, Record<string, string>>;
  const queryClient = new QueryClient();
  return (
    <>
      <Head>
        <title>Hitch and Hike</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        {/* Twitter */}
        <meta name="twitter:creator" content="https://twitter.com/hitch_and_hike" key="twhandle" />

        <meta property="og:image" content="https://hitchandhike.com/logo.png" key="ogimage" />
        <meta property="og:site_name" content="Hitch and Hike" key="ogsitename" />
      </Head>
      <IntlProvider
        locale={locale || 'en'}
        defaultLocale={defaultLocale}
        messages={localesMap[locale || 'en']}
      >
        <ConfigProvider locale={locale as unknown as Locale}>
          <ChakraProvider
            theme={extendTheme({
              colors: {
                black: '#000',
                brand: {
                  50: '##e7f1e8',
                  100: '#c5dcc7',
                  200: '#a0c5a5',
                  300: '#7ea172',
                  400: '#65a06c',
                  500: '#499251',
                  600: '#428449',
                  700: '#38733e',
                  800: '#2f6334',
                  900: '#1e4522',
                },
              },
              fonts: {
                body: 'Raleway, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
                heading:
                  'Raleway, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
              },
            })}
          >
            <QueryClientProvider client={queryClient}>
              <CookieConsentProvider>
                <UserAuthenticationProvider>
                  {/* should be nested in UseAuthenticationProvider ; because it's used */}
                  <ProfileProvider>
                    <ErrorProvider>
                      <GlobalStateProvider>
                        <FirebaseTrackingProvider>
                          <Component {...pageProps} err={err} />
                        </FirebaseTrackingProvider>
                      </GlobalStateProvider>
                    </ErrorProvider>
                  </ProfileProvider>
                </UserAuthenticationProvider>
              </CookieConsentProvider>
            </QueryClientProvider>
          </ChakraProvider>
        </ConfigProvider>
      </IntlProvider>
    </>
  );
}
