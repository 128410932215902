import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_MEASUREMENT_ID,
} as Record<string, unknown>;

if (!firebase.apps.length) {
  // in case of an emulator, dont use real instance
  firebase.initializeApp(firebaseConfig);
  if (process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST) {
    process.env.FIRESTORE_EMULATOR_HOST = process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST;
    console.log('Start using the emulator');
    firebase.firestore().useEmulator('localhost', 8080);
  }
  // enable analytics anyway
}

export function enableAnalytics(): void {
  // To enable analytics. https://firebase.google.com/docs/analytics/get-started

  //   only in the browser
  if (firebase.analytics) {
    firebase.analytics.isSupported().then((isSupported) => {
      if (isSupported && 'measurementId' in firebaseConfig) {
        firebase.analytics();
      }
    });
  }
}
// Check that `window` is in scope for the analytics module!

// export function enableAnonymousLogin(): void {
//   if (!firebase.auth().currentUser) {
//     firebase.auth().signInAnonymously();
//   }
// }
