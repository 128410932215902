import useError, { Error, FrontendError } from 'services/hooks/useError';
import React, { useEffect, useState } from 'react';
import { Col, Divider, Modal } from 'antd';
import ContactForm from 'components/ContactForm';

export const ErrorContext = React.createContext<Error>({
  errors: [],
  clearError: () => undefined,
  addError: () => undefined,
});

export const ErrorProvider = ({ children }: React.PropsWithChildren<unknown>): JSX.Element => {
  const { errors, clearError, addError } = useError();

  const [currentError, setCurrentError] = useState<FrontendError | undefined>();

  useEffect(() => {
    if (!currentError && errors.length > 0) {
      setCurrentError(errors[0]);
    }
  }, [errors, currentError]);

  const dismissError = (id?: string) => {
    if (id) {
      clearError(id);
    }
    setCurrentError(undefined);
  };

  return (
    <ErrorContext.Provider
      value={{
        errors,
        clearError,
        addError,
      }}
    >
      <Modal
        width={1400}
        visible={!!currentError}
        onOk={() => dismissError(currentError?.id)}
        onCancel={() => dismissError(currentError?.id)}
        title={'Sorry, we failed to process your request'}
      >
        <>
          <Col span={24}>
            <h3>What went wrong?</h3>
            <p>{currentError?.error}</p>
          </Col>
          <Divider />
          <Col span={24}>{'We know this is frustrating...Also for us..'}</Col>
          <Col span={24}>{'Could you please try again? Thank you for your patience.'}</Col>
          <Col span={24}>
            {
              'If it keeps on failing, contact us, those issues have our highest priority. We will solve this issue for you ASAP.'
            }
          </Col>
          <Col span={24}>
            <ContactForm
              withoutApps
              prefilledText={`The following unexpected error occurred. Can you help us further?\nThe error: ${currentError?.error}
            `}
            />
          </Col>
        </>
      </Modal>
      {children}
    </ErrorContext.Provider>
  );
};
