import { createContext, useState, useEffect, ReactNode } from 'react';
import firebase from 'firebase/app';
import { useRouter } from 'next/router';
import 'firebase/analytics';
import { enableAnalytics } from 'config/firebase/initialize';

export const FirebaseContext = createContext<{ tracking?: firebase.analytics.Analytics }>({
  tracking: undefined,
});

export const FirebaseTrackingProvider = (props: { children: ReactNode }): JSX.Element => {
  const router = useRouter();
  const [tracking, setTracking] = useState<firebase.analytics.Analytics | undefined>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      enableAnalytics();
    }
  }, []);

  useEffect(() => {
    setTracking(firebase.analytics());

    const handleRouteChange = (url: string) => {
      if (!tracking) {
        return;
      }

      tracking.logEvent('page_view', {
        page_location: url,
        page_title: document?.title,
      });
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracking]);

  return <FirebaseContext.Provider value={{ tracking }}>{props.children}</FirebaseContext.Provider>;
};
